<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <div class="mt-4">
      <on-boarding-summery
        v-if="vendor"
        :form="vendor"
        :primary-categories="primaryCategories"
        :secondary-categories="secondaryCategories"
      />
    </div>
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout';
import { getVendor } from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { format } from 'date-fns';
export default {
  name: 'VendorDetailsPage',
  components: {
    ContentLayout,
    OnBoardingSummery: () =>
      import('@/domain/vendor/components/OnBoardingSummery')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      vendor: null,
      loading: false
    };
  },
  computed: {
    ...mapFields('vendor', ['primaryCategories', 'secondaryCategories']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.$t('customer.vendorsList'),
          to: {
            name: 'customer-vendors-lookup'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.vendor?.businessName || '...',
          disabled: true,
          exact: true
        }
      ];
    }
  },
  created() {
    this.loadCategories();
    this.loadVendor();
  },
  methods: {
    ...mapActions('vendor', ['loadCategories']),
    async loadVendor() {
      try {
        this.loading = true;
        const vendor = await getVendor(this.id, {
          $populate: ['categories', 'files']
        });

        if (vendor.accountingBooksCertificationExpirationDate) {
          vendor.accountingBooksCertificationExpirationDate = format(
            new Date(vendor.accountingBooksCertificationExpirationDate),
            'dd/MM/yyyy'
          );
        }

        this.vendor = vendor;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
